<div class="app-shell flex flex-column h-screen relative">

  <div class="header flex-none w-full ">
    <header class="nitro-indexer-header pl-4 mb-2 flex align-items-center py-2 text-lg">

      <div
        (click)="onTitleClick()"
        class="text-xl flex"
      >
        <div class="logo font-bold">Speed</div>
        <div class="logo-2 font-bold">Indexer</div>
      </div>

      <div class="flex-none theme-switch">
        <p-button (click)="switchDarkTheme()"
                  icon="pi pi-sun"
                  title="Changer le thème"
                  [text]="true"
        ></p-button>
      </div>

      <p-overlayPanel #userOverlay>
        <div class="user-overlay-content">
          <button type="button" pButton
                  class="p-button-text p-button-plain"
                  title="Se déconnecter"
                  label="Se déconnecter"
                  (click)="onLogoutClick()"
          ></button>
        </div>
      </p-overlayPanel>
      <p-button class="logged-user flex relative cursor-pointer mx-3 p-2 text-sm"
                (click)="userOverlay.toggle($event)"
                size="small"
                [text]="true"
      >
        <span [class]="'pi pi-user mr-1 sm'"></span>
        <div *ngIf="loggedUser">
          {{ loggedUser.name! }}
        </div>
      </p-button>

      <p-overlayPanel #trusteeOverlay>
        <div class="user-overlay-content">
          <button type="button" pButton
                  class="p-button-text p-button-plain"
                  *ngIf="canChangeTrustee"
                  title="Changer de fiduciaire"
                  label="Changer de fiduciaire"
                  (click)="onChangeTrusteeClick()"
          ></button>
        </div>
      </p-overlayPanel>
      <div class="user-trustee flex-none relative cursor-pointer hover:bg-black-alpha-10 mx-3 p-2"
           *ngIf="trustee"
           (click)="trusteeOverlay.toggle($event)"
      >
        <div>Fidu</div>
        <div>{{ trustee.name! }}</div>
      </div>

      <div class="worklist">
        <idx-worklist-select></idx-worklist-select>
      </div>

      <div class="separator flex-1"></div>

      <div class="active-doc flex-none mx-3 text-right">
        <idx-document-worklist-navigator [showWorkListName]="false"
                                         [showPaginator]="false"
                                         [showActiveDocumentName]="true"
                                         [showActiveDocumentId]="true"
        ></idx-document-worklist-navigator>
      </div>

      <div class="worklist-navigator flex-none mx-3 ">
        <idx-document-worklist-navigator [showWorkListName]="false"
                                         [showPaginator]="true"
                                         [showActiveDocumentName]="false"
        ></idx-document-worklist-navigator>
      </div>
    </header>
  </div>

  <div class="content flex-1  h-full relative overflow-hidden">
    <router-outlet></router-outlet>
  </div>


  <div class="shell-messages block w-full absolute top-0 m-auto z-4 flex align-items-center flex-column">
    <div class="message-container  w-7 max-w-full">
      <p-message closable *ngFor="let msg of (messages$ | async) || []"
                 [severity]="msg.severity"
                 [text]="msg.summary"
      >
        {{ msg.detail }}
      </p-message>
    </div>
  </div>

  <p-toast position="center"
           styleClass="confirm-toast"
           [key]="ModalKeys.OPTIMISTIC_LOCK_MODAL_KEY"
           (onClose)="onOptimsticLockModalClose()"
           [preventOpenDuplicates]="true"
  >

    <ng-template let-message pTemplate="message">
      <div class="optimistic-lock">
        <div class="header">
          <i class="pi pi-exclamation-triangle"></i>
          <span class="m-2 text-lg font-bold">{{ message.summary }}</span>
        </div>

        <p>
          La version du formulaire (ou d'une partie du formulaire) que vous voulez enregistrer semble avoir été
          modifiée depuis qu'il a été chargé. Cela peut arriver par exemple lorsque plusieurs personnes modifient
          le même formulaire en même temps.
        </p>

        <div class="actions">
          <button type="button" pButton (click)="onOptimsticLockRefetch(message)"
                  nitForceFocus
                  label="Recharger" class="ui-button-primary"></button>

          <!-- TODO: handle reloading server version while preserving local changes and dusplaying diff (changed fields) to the user -->
          <!-- overwrite disabled to prevent data loss-->
          <!--        <button type="button" pButton (click)="onOptimistickLockOverwrite(message)"-->
          <!--                label="Écraser" class="ui-button-danger"></button>-->

        </div>
      </div>
    </ng-template>

  </p-toast>

</div>
<div class="banner">
  <nit-env-banner [showVersion]="false"
  ></nit-env-banner>
</div>
