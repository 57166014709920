import {Component, OnDestroy, OnInit} from "@angular/core";
import {CustomerDocumentColumn, CustomerDocumentColumns, CustomerDocumentRow, PaginationUtils, NitroLoggedUserService, SimpleDatePipePipe, CustomerComponent, DocumentStatusComponent} from "@fiscalteam/ngx-nitro-services";
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {WorkListNavigationStateService} from "../../service/work-list-navigation-state.service";
import {map} from "rxjs/operators";
import {take} from "rxjs";
import {Router, ActivatedRoute} from "@angular/router";
import {CustomerDocumentWorklistTableHelper} from "./customer-document-worklist-table-helper";
import {WorkListService} from "../../service/work-list-service";
import {AsyncPipe} from "@angular/common";


@Component({
  selector: 'idx-document-list-route',
  templateUrl: './document-list-route.component.html',
  styleUrls: ['./document-list-route.component.scss'],
  standalone: true,
  imports: [
    TableModule,
    AsyncPipe,
    SimpleDatePipePipe,
    CustomerComponent,
    DocumentStatusComponent
  ],
  providers: [
    CustomerDocumentWorklistTableHelper,
  ]
})
export class DocumentListRoute implements OnInit, OnDestroy {

  columns: CustomerDocumentColumn[] = [
    CustomerDocumentColumns.creationTime,
    CustomerDocumentColumns.customerName,
    CustomerDocumentColumns.name,
    CustomerDocumentColumns.status,
  ]


  constructor(
    public tableHelper: CustomerDocumentWorklistTableHelper,
    public navigationStateService: WorkListNavigationStateService,
    public router: Router,
  ) {
  }

  ngOnInit() {
    this.tableHelper.subscribe();
  }

  ngOnDestroy() {
    this.tableHelper.unsubscribe();
  }

  loadPage(event: TableLazyLoadEvent) {
    const curPagination = this.tableHelper.getCurPagination() || PaginationUtils.newPagination(0, 200);
    const newPagination = PaginationUtils.withLazyLoadEvent(curPagination, event);
    this.tableHelper.search(newPagination);
  }

  onRowClick(row: CustomerDocumentRow) {
    this.navigationStateService.getNavigationState$().pipe(
      take(1),
      map(s => s.workListState?.pageItems || []),
      map(items => items.find(item => item.id === row.customerDocument.id))
    ).subscribe(foundWorkItem => {
      if (foundWorkItem) {
        this.navigationStateService.navigateToItem(foundWorkItem);
        this.router.navigate(['/', row.customerDocument.id]);
      }
    });
  }
}
