import { WsNitroApiError } from "@fiscalteam/nitro-domain-client";
import { MessageService } from "primeng/api";
import {NitroMessage} from "@fiscalteam/ngx-nitro-services"; // Import Message from 'primeng/message'

interface MessageWithData extends NitroMessage {
  data?: any;
  key?: string;
  sticky?: boolean;
  detail?: string;
  text?: string;
  escape?: boolean;
  style?: any;
  styleClass?: string;
}

export class AppShellModalHelpers {
  static OPTIMISTIC_LOCK_ERROR_CODE = 'OPTIMISTIC_LOCK';
  static OPTIMISTIC_LOCK_MODAL_KEY = 'optimistic-lock-errors';

  static showOptimisticLockModal(
    messageService: MessageService,
    error: WsNitroApiError,
    onReload: () => void,
    onOverwrite?: () => void
  ): void {
    const message = this.createOptimisticLockMessage(error, onReload, onOverwrite);

    // Ensure severity is always a valid string
    if (!message.severity) {
      message.severity = 'warn'; // Default to 'warn' if severity is missing or null
    }

    messageService.add(message); // Add the message to the service
  }

  static callOptimisticLockReloadCallback(message: MessageWithData) {
    if (message && message.data && message.data.onReload) {
      if (typeof message.data.onReload === "function") {
        message.data.onReload();
      }
    }
  }

  static callOptimisticLockOverwriteCallback(message: MessageWithData) {
    if (message && message.data && message.data.onOverwrite) {
      if (typeof message.data.onOverwrite === "function") {
        message.data.onOverwrite();
      }
    }
  }

  private static createOptimisticLockMessage(
    error: WsNitroApiError,
    onReload: () => void,
    onOverwrite?: () => void
  ): MessageWithData {
    return {
      key: AppShellModalHelpers.OPTIMISTIC_LOCK_MODAL_KEY,
      sticky: true,
      closable: true,
      summary: `${error.message || 'Modifications conflictuelles'}`,
      detail: '',
      severity:  'warn',
      data: {
        onReload: onReload,
        onOverwrite: onOverwrite,
      }
    };
  }
}
