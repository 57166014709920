import {Injectable, model} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class ThemeService {

  selectedTheme$ = new BehaviorSubject<'light' | 'dark'>('light');


  constructor() {
    const systemDarkTheme = this.isSystemDarkTheme();
    const initialTheme = systemDarkTheme ? 'dark' : 'light';
    this.setPageTheme(initialTheme);
  }

  switchDarkTheme() {
    const curTheme = this.selectedTheme$.getValue();
    if (curTheme === "light") {
      this.setPageTheme('dark');
    } else {
      this.setPageTheme('light');
    }
  }

  private isSystemDarkTheme() {
    if (window) {
      return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    } else {
      return false;
    }
  }

  private setPageTheme(theme: "light" | "dark") {
    this.selectedTheme$.next(theme);
    const doc = document;
    if (doc == null) {
      return;
    }
    const htmlElements = doc.getElementsByTagName('html');
    if (htmlElements && htmlElements.length === 1) {
      const htmlElement = htmlElements[0];
      if (theme == "light") {
        htmlElement.classList.remove('speed-dark')
      } else {
        htmlElement.classList.add('speed-dark')
      }
    }
  }
}
