import {enableProdMode, importProvidersFrom, LOCALE_ID} from '@angular/core';
import {environment} from './environments/environment';
import {NitroAuthModule, nitroAuthWithAngularOauth2Oidc, NitroConfigModule, nitroConfigWithKeyValues, NitroServiceModule, tryLoadNitroBackendUriFromJson} from "@fiscalteam/ngx-nitro-services";
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {bootstrapApplication, BrowserModule} from "@angular/platform-browser";
import {AppComponent} from "./app/app.component";
import {AppRoutingModule} from "./app/app-routing.module";
import {MenuModule} from "primeng/menu";
import {MessagesModule} from "primeng/messages";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {provideAnimations} from "@angular/platform-browser/animations";
import {provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {providePrimeNG} from "primeng/config";
import Aura from '@primeng/themes/aura';
import { IndexerFrontConfigService } from './app/config/indexer-front-config.service';
import {DefaultIndexerFrontConfigs} from "./app/config/indexer-front-default-config";
registerLocaleData(localeFr, 'fr-FR');

if (environment.production) {
  enableProdMode();
}

tryLoadNitroBackendUriFromJson(environment.domainAppUri).then(apiUri => {
  return bootstrapApplication(AppComponent, {
    providers: [
      importProvidersFrom(
        BrowserModule,
        AppRoutingModule,
        NitroConfigModule.forRoot({
          domainApiUri: apiUri,
          domainApiClient: true,
          applicationName: "nitro-indexer-front",
          configValues: environment.configValues,
          asyncConfigValuesUri: environment.runtimeConfigPath,
          extraFeatures: [
            nitroConfigWithKeyValues(DefaultIndexerFrontConfigs, 0)
          ]
        }),
        NitroAuthModule.forRoot({
          implementation: nitroAuthWithAngularOauth2Oidc(),
          httpInterceptor: true,
        }),
        NitroServiceModule.forRoot(),
        MenuModule,
        MessagesModule,
        InputTextModule,
        FormsModule
      ),
      { provide: IndexerFrontConfigService, useClass: IndexerFrontConfigService },
      { provide: LOCALE_ID, useValue: 'fr-FR' },
      provideAnimations(),
      provideHttpClient(withInterceptorsFromDi()),
      providePrimeNG({
        theme: {
          preset: Aura,
          options: {
            darkModeSelector: '.speed-dark',
          },
        },
      }),
    ]
  }).catch(err => console.error(err));
});

