<div class="new-thirdparty-form flex flex-column justify-content-start" *ngIf="thirdParty">

  <div class="row p-field">
    <label for="officialName" class="block">Nom</label>
    <input type="text" pInputText
           name="officialName" id="officialName"
           class="w-full"
           [(ngModel)]="thirdParty.officialName"
           (ngModelChange)="fireChanges()"
           autofocus
    >
  </div>


  <div class="row p-field">
    <label for="vatNumber" class="block">Numéro de tva</label>
    <nit-vat-number-input name="vatNumber" id="vatNumber"
                          class="w-full"
                          [(ngModel)]="thirdParty.vatNumber"
                          (ngModelChange)="onVatNumberChange($event)"
                          [disabled]="thirdParty.thirdPartyType !== 'LEGAL_ENTITY'"
    ></nit-vat-number-input>
  </div>

  <div class="row p-field">
    <p-checkbox name="ignore"
                [binary]="true"
                [ngModel]="thirdParty.thirdPartyType === 'PERSON_ENTITY'"
                (ngModelChange)="onPersonEntityValueChange($event)"
                inputId="personne"
                class="mr-2"


    ></p-checkbox>
    <label for="personne">Personne physique</label>
  </div>

  <div class="row p-field">
    <label for="iban" class="block">IBANs</label>
    <nit-thirdparty-identifiers-input id="iban"
                                      [(ngModel)]="identifiers"
                                      [identifierType]="WsThirdPartyIdentifierType.Iban"
                                      (ngModelChange)="fireChanges()"
                                      [showAddButton]="true"
    ></nit-thirdparty-identifiers-input>
  </div>

  <div class="row p-field">
    <label for="phone" class="block">Téléphones</label>
    <nit-thirdparty-identifiers-input id="phone"
                                      [(ngModel)]="identifiers"
                                      [identifierType]="WsThirdPartyIdentifierType.PhoneNumber"
                                      (ngModelChange)="fireChanges()"
                                      [showAddButton]="true"
    ></nit-thirdparty-identifiers-input>
  </div>

  <div class="row p-field">
    <label for="email" class="block">Emails</label>
    <nit-thirdparty-identifiers-input id="email"
                                      [(ngModel)]="identifiers"
                                      [identifierType]="WsThirdPartyIdentifierType.Email"
                                      (ngModelChange)="fireChanges()"
                                      [showAddButton]="true"
    ></nit-thirdparty-identifiers-input>
  </div>

</div>
