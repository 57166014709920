import {Component, ElementRef, Host, Input, OnDestroy, OnInit, output, Output} from '@angular/core';
import {FieldInputTemplateDirective, FieldProblemTypeComponent, FieldValueType, IndexingFieldInputComponent, NitroLoggedUserService, SortingDocumentIndexingState} from '@fiscalteam/ngx-nitro-services';
import {WsFieldIdentificationValue, WsFieldIdentificationValueStatus, WsFieldProblemType, WsRefWsTrustee} from '@fiscalteam/nitro-domain-client';
import {BehaviorSubject, EMPTY, Observable, of} from 'rxjs';
import {IndexerDocumentFormFieldModel} from "../document-fields-form/indexer-document-form-field-model";
import {DocumentIndexingStateService} from "../document-fields-form/document-indexing-state-service";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {Message} from "primeng/message";

@Component({
  selector: 'idx-document-field',
  templateUrl: './document-field.component.html',
  styleUrls: ['./document-field.component.scss'],
  imports: [
    NgIf,
    IndexingFieldInputComponent,
    AsyncPipe,
    FieldInputTemplateDirective,
    FieldProblemTypeComponent,
    Message,
    NgForOf
  ],
  standalone: true
})
export class DocumentFieldComponent implements OnInit, OnDestroy {

  @Input()
  set formFieldModel(value: IndexerDocumentFormFieldModel | undefined) {
    this.fieldModel = value;
    if (value) {
      this.fieldTypedValue$ = value.fieldModel.anyTypedValue$;
    } else {
      this.fieldTypedValue$ = of(undefined);
    }
  }

  fieldModel: IndexerDocumentFormFieldModel | undefined;
  fieldTypedValue$: Observable<FieldValueType | undefined> = EMPTY;

  @Input()
  readOnly: boolean = false;
  @Input()
  disabled: boolean = false;
  @Input()
  showInfoMessage: boolean = true;
  @Input()
  countryCode?: string = undefined;

  //  The 'IndexerDocumentFormFieldModel' contains the state of the indexing field input, and thus the form service
  // can use it to monitor whether the indexer filled that field.
  // However, for soem events handled in this template directly, we want to inform the parent form component
  // that this field can be considered indexed expliocitely
  fieldIndexed = output<boolean>();

  trusteeRef?: WsRefWsTrustee | undefined;
  indexingState$: Observable<SortingDocumentIndexingState | undefined> = of(undefined);

  busy$ = new BehaviorSubject<boolean>(false);
  submitErrors?: string[];


  WsFieldProblemType = WsFieldProblemType;

  constructor(
    private loggedUserService: NitroLoggedUserService,
    private indexingStateService: DocumentIndexingStateService,
    @Host() private elementRef: ElementRef,
  ) {
  }

  ngOnInit(): void {
    this.trusteeRef = this.loggedUserService.getActiveTrusteeRef();
    this.indexingState$ = this.indexingStateService.indexingState$;
  }

  ngOnDestroy() {
  }

  focusInput() {
    if (this.elementRef && this.elementRef.nativeElement) {
      const htmlElement: HTMLElement = this.elementRef.nativeElement;
      const inputElement: HTMLInputElement | null = htmlElement.querySelector('input, button');
      if (inputElement) {
        inputElement.focus();
      } else if (htmlElement) {
        htmlElement.focus({});
      }
    }
  }

  onNoValueFoundCLick(eventValue: string, problemType: WsFieldProblemType) {
    if (this.fieldModel) {

      // Set local values to submit
      this.fieldModel.pendingSubmitLocalChanges = Object.assign({}, this.fieldModel.pendingSubmitLocalChanges, {
        fieldProblemType: problemType,
        valueStatus: WsFieldIdentificationValueStatus.Problem,
        fieldProblemDetails: eventValue,
      } as Partial<WsFieldIdentificationValue>);

      this.fieldModel.fieldModel.typedValueUpdateSource$.next(undefined);
      this.fieldIndexed.emit(true);
    }
  }
}
