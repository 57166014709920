import {Component, effect, model, OnInit} from '@angular/core';
import {MessageService} from "primeng/api";
import {firstValueFrom, Observable, of} from "rxjs";
import {ForceFocusDirective, NitroEnvBannerComponent, NitroLoggedUserService, NitroMessage, NitroMessageService, TrusteeService} from "@fiscalteam/ngx-nitro-services";
import {AppShellModalHelpers} from "./app-shell-modal-helpers";
import {VERSION} from "../../../version";
import {WsNitroUser, WsRefWsTrustee, WsTrustee} from "@fiscalteam/nitro-domain-client";
import {ActivatedRoute, Router, RouterOutlet} from "@angular/router";
import {WorkListService} from "../../service/work-list-service";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {Button, ButtonDirective} from "primeng/button";
import {WorklistSelectComponent} from "../../worklist-select/worklist-select.component";
import {DocumentWorklistNavigatorComponent} from "../../document-worklist-navigator/document-worklist-navigator.component";
import {MessagesModule} from "primeng/messages";
import {Toast} from "primeng/toast";
import {CommonModule, NgIf} from "@angular/common";
import {updatePrimaryPalette} from "@primeng/themes";
import {Message} from "primeng/message";
import {ThemeService} from "../theme-service";

@Component({
  selector: 'idx-app-shell',
  templateUrl: './app-shell.component.html',
  styleUrls: ['./app-shell.component.scss'],
  standalone: true,
  imports: [
    OverlayPanelModule,
    ButtonDirective,
    Button,
    WorklistSelectComponent,
    DocumentWorklistNavigatorComponent,
    RouterOutlet,
    MessagesModule,
    Toast,
    NitroEnvBannerComponent,
    ForceFocusDirective,
    CommonModule,
    Message
  ],
  providers: [MessageService, ThemeService]
})
export class AppShellComponent implements OnInit {

  messages$: Observable<NitroMessage[]> = of([]);

  ModalKeys = AppShellModalHelpers;

  version = VERSION;
  loggedUser: WsNitroUser | undefined = undefined;
  trustee: WsTrustee | null = null;
  canChangeTrustee: boolean = false;

  constructor(
    private nitroLoggedUserService: NitroLoggedUserService,
    private messageService: NitroMessageService,
    private trusteeService: TrusteeService,
    private primengMessageService: MessageService,
    private worklistService: WorkListService,
    private activatedRoute: ActivatedRoute,
    private themeService: ThemeService,
    private router: Router,
  ) {
  }

  async ngOnInit(): Promise<void> {
    updatePrimaryPalette({
      50: '{blue.50}',
      100: '{blue.100}',
      200: '{blue.200}',
      300: '{blue.300}',
      400: '{blue.400}',
      500: '{blue.500}',
      600: '{blue.600}',
      700: '{blue.700}',
      800: '{blue.800}',
      900: '{blue.900}',
      950: '{blue.950}'
    });
    // Display messages from message service
    this.messages$ = this.messageService.getMessages$();

    // Display logged user & its trustee
    this.loggedUser = await this.nitroLoggedUserService.getLoggedUser$();
    if (this.nitroLoggedUserService.hasTrusteeSet()) {
      const trusteeRef = this.nitroLoggedUserService.getActiveTrusteeRef() as WsRefWsTrustee;
      this.trustee = (await firstValueFrom(this.trusteeService.getTrustee$(trusteeRef))) ?? null;
    } else {
      this.trustee = null;
    }
    this.canChangeTrustee = this.nitroLoggedUserService.getAvailableTrusteeUsers().length > 1 || (this.loggedUser?.nitroAdmin || false);

    // Initialize the work list based on query params
    this.startInitialWorkList();
  }

  onLogoutClick() {
    this.nitroLoggedUserService.logout('/');
  }

  onChangeTrusteeClick() {
    this.nitroLoggedUserService.clearActiveTrusteeRef();
  }

  discardMessages() {
    this.messageService.discardAll();
  }

  onTitleClick() {
    this.startInitialWorkList();
    this.router.navigate(['/']);
  }

  onOptimsticLockModalClose() {
    this.primengMessageService.clear(AppShellModalHelpers.OPTIMISTIC_LOCK_MODAL_KEY);
  }

  onOptimsticLockRefetch(message: any) {
    AppShellModalHelpers.callOptimisticLockReloadCallback(message);
    this.primengMessageService.clear(AppShellModalHelpers.OPTIMISTIC_LOCK_MODAL_KEY);
  }

  switchDarkTheme() {
    this.themeService.switchDarkTheme()
  }

  private startInitialWorkList() {
    const queryParams = this.activatedRoute.snapshot.queryParamMap;
    this.worklistService.initStartWorkList(queryParams);
  }

}
