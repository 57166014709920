import {Component, Input, OnInit} from '@angular/core';
import {distinctUntilChanged, EMPTY, Observable, shareReplay, take} from "rxjs";
import {map} from "rxjs/operators";
import {WorkListStateService} from "../service/work-list-state.service";
import {WorkListNavigationStateService} from "../service/work-list-navigation-state.service";
import {WorkListItem} from "../service/worklist/work-list-item";
import {AsyncPipe, NgIf} from "@angular/common";
import {ButtonDirective} from "primeng/button";

/**
 * A navigator for navigation within an active workList provided by the workList item service.
 *
 * This component relies on the presence of the workList service as well as the worklist navigation service.
 */
@Component({
  selector: 'idx-document-worklist-navigator',
  templateUrl: './document-worklist-navigator.component.html',
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonDirective,
    NgIf
  ],
  styleUrls: ['./document-worklist-navigator.component.scss']
})
export class DocumentWorklistNavigatorComponent implements OnInit {

  @Input()
  showWorkListName = true;
  @Input()
  showActiveDocumentName = true;
  @Input()
  showActiveDocumentId = false;
  @Input()
  showPaginator = true;
  @Input()
  disabled = false;

  @Input()
  showPaginatorFirstLast = true;
  @Input()
  showPaginatorPrevNext = true;
  @Input()
  showPaginatorIndexTotalCount = true;
  @Input()
  redirected = false;

  workListName$: Observable<string | undefined> = EMPTY;
  activeDocumentLabel$: Observable<string | undefined> = EMPTY;
  activeDocumentId$: Observable<number | undefined> = EMPTY;
  hasPrevDoc$: Observable<boolean> = EMPTY;
  hasNextDoc$: Observable<boolean> = EMPTY;
  docIndex$: Observable<number | undefined> = EMPTY;
  totalCount$: Observable<number> = EMPTY;

  constructor(
    private workListStateService: WorkListStateService,
    private workListNavigationStateService: WorkListNavigationStateService,
  ) {
  }

  ngOnInit(): void {
    this.workListName$ = this.workListStateService.getWorkListState$().pipe(
      map(l => l == null ? `Aucune liste` : l.workListFilter.label),
      shareReplay({bufferSize: 1, refCount: true}),
    );
    const activeWorkListItem$: Observable<WorkListItem | undefined> = this.workListNavigationStateService.getNavigationState$().pipe(
      map(item => item.activeItem),
      distinctUntilChanged(),
      shareReplay({bufferSize: 1, refCount: true}),
    );
    this.activeDocumentLabel$ = activeWorkListItem$.pipe(
      map(i => i == null ? `Aucun document` : i.document.name),
      shareReplay({bufferSize: 1, refCount: true}),
    );
    this.activeDocumentId$ = activeWorkListItem$.pipe(
      map(i => (i == null || i.id == null) ? undefined : i.id),
      shareReplay({bufferSize: 1, refCount: true}),
    );
    this.hasPrevDoc$ = this.workListNavigationStateService.getNavigationState$().pipe(
      map(p => p?.itemIndex != null && p.itemIndex > 0),
      shareReplay({bufferSize: 1, refCount: true}),
    );
    this.hasNextDoc$ = this.workListNavigationStateService.getNavigationState$().pipe(
      map(s => s?.itemIndex != null && s.itemIndex < (s.workListState?.totalCount || 0)),
      shareReplay({bufferSize: 1, refCount: true}),
    );
    this.docIndex$ = this.workListNavigationStateService.getNavigationState$().pipe(
      map(p => p?.itemIndex),
      shareReplay({bufferSize: 1, refCount: true})
    );
    this.totalCount$ = this.workListStateService.getWorkListState$().pipe(
      map(s => s == null ? 0 : s.totalCount),
      shareReplay({bufferSize: 1, refCount: true}),
    );
  }


  onNavigateFirstClick() {
    this.workListNavigationStateService.navigateToItemIndex(0);
  }

  onNavigateLastClick() {
    this.totalCount$.pipe(
      take(1),
    ).subscribe(i => this.workListNavigationStateService.navigateToItemIndex(i - 1));
  }

  onNavigateDeltaClick(delta: number) {
    this.workListNavigationStateService.navigateToItemAtIndexDelta(delta);
  }

}
